$font_a: arial,
Verdana;
$font_s: "游明朝体",
"Yu Mincho",
YuMincho,
"ヒラギノ明朝 Pro W3",
"Hiragino Mincho Pro",
serif,
"ＭＳ Ｐ明朝",
"MS PMincho";
$font_g: 'Noto Sans JP',
"游ゴシック",
"Yu Gothic",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Osaka,
"Avenir Next",
Verdana,
sans-serif;
$font_r: roboto,
sans-serif;

$xs: "max-width: 320px"; // iPhone5s
$sm: "max-width: 599px"; // iPhone6, iPhone6 Plus
$tab: "max-width: 768px"; // Tablet
$lap: "max-width: 1024px"; // Desktop
$lag: "min-width: 1025px";

$c_black: rgb(14, 24, 14);
$c_link: rgb(0, 0, 255);
$c_bg: #f2f2f2;
$c_bg-header: #ccc;
$c_bg-hooter: #ccc;
$c_navy: #2e3192;

@import "_normalize";

* {
    box-sizing: border-box;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $c_black;
    font-family: $font_g;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 200%;
    margin: 0;
    font-feature-settings: "palt";
}

a {
    color: $c_black;
    text-decoration: underline;
    transition: all 0.2s;

    &:hover {
        text-decoration: none;
        color: $c_black;
        opacity: .7;
    }
}

.t_right {
    text-align: right !important;
}

.t_center {
    text-align: center !important;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &.wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    &.center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.container {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
}

.box-fadein img,
.box-fadein iframe {
    opacity: 0;
    -webkit-transition: opacity 1.2s;
    transition: opacity 1.2s;

    &.lazyloaded {
        opacity: 1;
    }
}

.view_pc {
    @media all and ($sm) {
        display: none;
    }
}

.view_sp {
    display: none;

    @media all and ($sm) {
        display: block;
    }
}

.c_navy {
    color: $c_navy;
}

#loading {
    width: 100vw;
    height: 100vh;
    transition: all .5s;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    -webkit-justify-content: center;
    justify-content: center;

    &.loaded {
        opacity: 0;
        visibility: hidden;
    }
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }

    16.667% {
        box-shadow: 9984px -10px 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }

    33.333% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }

    50% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px -10px 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }

    66.667% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }

    83.333% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px -10px 0 0 $c_navy;
    }

    100% {
        box-shadow: 9984px 0 0 0 $c_navy, 9999px 0 0 0 $c_navy, 10014px 0 0 0 $c_navy;
    }
}

.inview {

    .op1,
    .op2,
    .op3,
    .op4,
    .op5,
    .op6,
    .op7,
    .op8,
    .op9 {
        opacity: 0;
    }

    &.on {
        .op1 {
            animation: my_opacity .8s ease-in-out .8s forwards;
        }

        .op2 {
            animation: my_opacity .8s ease-in-out 1.2s forwards;
        }

        .op3 {
            animation: my_opacity .8s ease-in-out 1.6s forwards;
        }

        .op4 {
            animation: my_opacity .8s ease-in-out 2s forwards;
        }

        .op5 {
            animation: my_opacity .8s ease-in-out 2.4s forwards;
        }

        .op6 {
            animation: my_opacity .8s ease-in-out 2.8s forwards;
        }

        .op7 {
            animation: my_opacity .8s ease-in-out 3.2s forwards;
        }

        .op8 {
            animation: my_opacity .8s ease-in-out 3.6s forwards;
        }

        .op9 {
            animation: my_opacity 0.8s ease-in-out 4s forwards;
        }
    }
}

@keyframes my_opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* ============================================
  header
============================================ */
.header {
    background-color: #fff;

    @media all and ($sm) {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 2px 3px rgba($color: #000, $alpha: .1);
        width: 100%;
        z-index: 100;

        .logo {
            width: 170px;
            padding-left: 15px;

            a {
                display: block;
            }
        }
    }

    .container {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 10px 0;

        @media all and ($sm) {
            padding: 0;
        }
    }

    .main-nav {
        background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
        background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
        background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
        background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));

        @media all and ($sm) {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            padding: 70px 15px 0;
            z-index: 10;
            display: none;
        }

        .main-nav__list {
            padding: 0;
            -webkit-justify-content: center;
            justify-content: center;

            @media all and ($sm) {
                padding-bottom: 10px;
            }

            li {
                list-style-type: none;
                margin: 0 40px;
                font-size: 15px;

                @media all and ($sm) {
                    line-height: 100%;
                    margin: 0 0 25px;
                    font-size: 15px;
                    width: 100%;
                }

                &.menu-item-has-children {
                    position: relative;

                    >a {
                        border: none;
                        font-weight: bold;
                        background: none;
                    }

                    &:hover {
                        .sub-menu {
                            display: block;
                            position: absolute;
                            left: 0;
                            z-index: 10;
                            width: 180px;
                            border-top: solid 1px #fff;

                            @media all and ($sm) {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                position: static;
                                width: 100%;
                                border: none;
                            }
                        }
                    }
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    padding: 20px 0;
                    display: block;

                    &:hover {
                        cursor: pointer;
                    }

                    @media all and ($sm) {
                        border-bottom: solid 1px #fff;
                        display: block;
                        padding: 0 0 10px;
                        background: url(../images/arrow_nav.svg) no-repeat right top 3px;
                        background-size: 5px;
                    }
                }

                .sub-menu {
                    display: none;
                    margin: 0;
                    background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
                    background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                    background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                    background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));
                    padding: 0;

                    @media all and ($sm) {
                        padding: 8px 0 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        -webkit-justify-content: space-between;
                        justify-content: space-between;
                        background: none;
                    }

                    li {
                        display: block;
                        font-size: 14px;
                        white-space: nowrap;
                        margin: 0;
                        border-bottom: solid 1px #fff;

                        @media all and ($sm) {
                            width: calc(50% - 6px);
                            margin: 0 0 15px;
                        }

                        a {
                            display: block;
                            padding: 20px;

                            @media all and ($sm) {
                                padding: 0 0 8px;
                            }
                        }
                    }
                }
            }
        }

        .btn_contact {
            padding: 0;
            margin-bottom: 6px;

            a {
                width: 100%;
            }

            svg {
                margin-right: 5px;
                width: 23px;
            }

            span {
                display: block;
                font-weight: bold;
            }
        }

        .btns {
            -webkit-justify-content: space-between;
            justify-content: space-between;
            width: 100%;

            .btn {
                width: calc(50% - 3px);

                &:last-child {
                    margin: 0;
                }

                a {
                    -webkit-justify-content: center;
                    justify-content: center;
                    font: 700 20px/120% $font_g;
                    border: solid 1px #fff;
                    height: 60px;
                    text-decoration: none;
                    color: #fff;
                    width: 100%;

                    svg {
                        margin-right: 5px;
                    }
                }

                &.tel {
                    a {
                        line-height: 100%;
                        padding-top: 2px;
                        background-color: #171a70;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        >span {
                            -webkit-justify-content: center;
                            justify-content: center;
                        }
                    }

                    svg {
                        width: 10px;
                        height: 25px;
                        margin: -2px 4px 0 0;
                        fill: #fff;
                    }

                    .number {
                        font: 500 18px/100% $font_r;
                        display: block;
                        color: #fff;
                    }

                    .time {
                        display: block;
                        text-align: center;
                        font-size: 13px;
                        height: 20px;
                    }
                }

                &.line {
                    a {
                        background-color: #171a70;
                        font-size: 15px;
                    }

                    svg {
                        width: 30px;
                    }

                    .cls-1 {
                        fill: #00b900;
                    }

                    .cls-2 {
                        fill: #fff;
                    }
                }
            }
        }

    }

    .tel {
        text-align: right;
        margin-right: 20px;

        @media all and ($sm) {
            margin: 0;
        }

        .time {
            font-size: 12px;

            @media all and ($sm) {
                display: none;
            }
        }

        a {
            text-decoration: none;

            @media all and ($sm) {
                display: block;
                width: 60px;
                height: 60px;
                line-height: 80px;
                text-align: center;
                background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
                background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));
            }
        }

        .number {
            font: 500 26px/100% $font_r;
            color: $c_navy;

            @media all and ($sm) {
                display: none;
            }
        }

        svg {
            fill: $c_navy;
            width: 12px;
            height: 20px;
            margin-right: 6px;

            @media all and ($sm) {
                width: 21px;
                height: 32px;
                fill: #fff;
                margin: 0;
            }
        }
    }

    .btn_contact {
        @media all and ($sm) {
            padding-right: 60px;
        }

        a {
            color: #fff;
            text-decoration: none;
            padding: 20px;
            background: -webkit-gradient(linear, 45deg, from(#ff581b), to(rgba(255, 172, 51, .8)));
            background: -webkit-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, .8));
            background: -moz-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, .8));
            background: linear-gradient(45deg, #ff581b, rgba(255, 172, 51, .8));

            @media all and ($sm) {
                padding: 0;
                width: 60px;
                height: 60px;
                -webkit-justify-content: center;
                justify-content: center;
            }
        }

        span {
            font-weight: bold;

            @media all and ($sm) {
                display: none;
            }
        }

        svg {
            width: 21px;
            margin-right: 10px;

            @media all and ($sm) {
                width: 30px;
                height: 23px;
                margin: 0;
            }
        }

        .cls-1 {
            fill: #fff;
        }
    }
}

#menuButton {
    display: none;
    width: 42px;
    height: 42px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 11;

    @media all and ($sm) {
        display: block;
    }

    span {
        display: block;
        background: $c_navy;
        width: 24px;
        height: 2px;
        position: absolute;
        left: 9px;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;

        &:first-child {
            top: 12px;
        }

        &:nth-child(2) {
            margin-top: -1px;
            top: 50%;
        }

        &:last-child {
            bottom: 12px;
        }
    }

    &.active {
        span {
            background-color: #fff;

            &:first-child {
                -webkit-transform: translateY(8px) rotate(45deg);
                -moz-transform: translateY(8px) rotate(45deg);
                -ms-transform: translateY(8px) rotate(45deg);
                transform: translateY(8px) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                -webkit-transform: translateY(-8px) rotate(-45deg);
                -moz-transform: translateY(-8px) rotate(-45deg);
                -ms-transform: translateY(-8px) rotate(-45deg);
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }
}

.breadcrumb {
    text-align: right;
    padding: 10px 0;

    .breadcrumb__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;

        li {
            &:after {
                content: ">";
                margin: 0 10px 0 13px;
            }

            &:last-child:after {
                content: "";
                margin: 0;
            }
        }
    }
}

.main {
    @media all and ($sm) {
        padding-top: 60px;
    }
}

/* ============================================
  area_contact
============================================ */
.area_contact {
    background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
    background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
    background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
    background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));

    .container {
        width: 950px;
        background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
        background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
        background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
        background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));
        padding: 56px 0 50px;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
        }

        &:before {
            width: 70px;
            height: 70px;
            top: -14px;
            left: -24px;
            background-color: rgba($color: #aec9e4, $alpha: .7);

            @media all and ($sm) {
                width: 50px;
                height: 50px;
                left: 10px;
            }
        }

        &:after {
            width: 35px;
            height: 35px;
            top: 31px;
            left: 20px;
            background-color: rgba($color: #fff, $alpha: .5);

            @media all and ($sm) {
                width: 25px;
                height: 25px;
                top: 20px;
                left: 43px;
            }
        }
    }

    h2 {
        text-align: center;
        font: 700 34px/130% $font_g;
        margin-bottom: 30px;
        color: #fff;

        @media all and ($sm) {
            font-size: 30px;
            margin-bottom: 10px;
        }
    }

    p {
        text-align: center;
        font: 400 18px/180% $font_g;
        margin-bottom: 30px;
        color: #fff;

        @media all and ($sm) {
            font-size: 15px;
        }
    }

    .btns {
        -webkit-justify-content: center;
        justify-content: center;
        width: 830px;
        margin: 0 auto;

        @media all and ($sm) {
            width: 100%;
            padding: 0 15px;
        }
    }

    .btn {
        width: calc(100% / 3 - 7px);
        margin-right: 10px;

        &:last-child {
            margin: 0;
        }

        a {
            -webkit-justify-content: center;
            justify-content: center;
            font: 700 20px/120% $font_g;
            border: solid 1px #fff;
            height: 100px;
            text-decoration: none;
            color: #fff;

            @media all and ($sm) {
                height: 70px;
            }

            svg {
                margin-right: 15px;
            }
        }

        &.mail {
            @media all and ($sm) {
                width: 100%;
                margin: 0 0 6px;
            }

            a {
                background: -webkit-gradient(linear, left top, right top, from(#ff581b), to(rgba(255, 172, 51, .8)));
                background: -webkit-linear-gradient(left, #ff581b, rgba(255, 172, 51, .8));
                background: -moz-linear-gradient(left, #ff581b, rgba(255, 172, 51, .8));
                background: linear-gradient(to right, #ff581b, rgba(255, 172, 51, .8));

                @media all and ($sm) {
                    border: none;

                    br {
                        display: none;
                    }

                    svg {
                        width: 23px;
                        margin-right: 8px;
                    }
                }

                @media all and ($xs) {
                    font-size: 18px;
                }
            }

            .cls-1 {
                fill: #fff;
            }
        }

        &.tel {
            @media all and ($sm) {
                width: calc(50% - 3px);
                margin-right: 6px;
            }

            a {
                line-height: 100%;
                padding-top: 2px;
                background-color: #171a70;

                >span {
                    -webkit-justify-content: center;
                    justify-content: center;
                }
            }

            svg {
                width: 16px;
                height: 25px;
                margin: 2px 5px 0 0;
                fill: #fff;

                @media all and ($sm) {
                    width: 10px;
                    margin: -2px 4px 0 0;
                }

                @media all and ($xs) {
                    width: 9px;
                    height: 18px;
                }
            }

            .number {
                font: 500 30px/100% $font_r;
                margin-bottom: 6px;

                @media all and ($sm) {
                    font-size: 18px;
                    margin: 0;
                }

                @media all and ($xs) {
                    font-size: 15px;
                }
            }

            .time {
                display: block;
                text-align: center;
                font-size: 13px;
                height: 20px;

                @media all and ($xs) {
                    font-size: 11px;
                }
            }
        }

        &.line {
            @media all and ($sm) {
                width: calc(50% - 3px);
            }

            a {
                background-color: #171a70;

                @media all and ($sm) {
                    font-size: 15px;

                    svg {
                        width: 30px;
                    }
                }

                @media all and ($xs) {
                    font-size: 13px;

                    svg {
                        margin-right: 8px;
                    }
                }
            }

            .cls-1 {
                fill: #00b900;
            }

            .cls-2 {
                fill: #fff;
            }
        }
    }
}

/* ============================================
  footer
============================================ */
.footer {
    position: relative;
    background-color: #fff;

    &:after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        background-color: #f0f5fa;
        width: 50%;
        top: 0;
        left: 50%;
        z-index: 5;

        @media all and ($sm) {
            display: none;
        }
    }

    .foot_logo {
        margin-bottom: 26px;
    }

    address {
        font-style: normal;
        background: url(../images/i_company.svg) no-repeat left top 7px;
        background-size: 14px;
        padding-left: 20px;
    }

    .tel {
        background: url(../images/i_tel.svg) no-repeat left top 7px;
        background-size: 10px;
        padding-left: 20px;

        a {
            text-decoration: none;
        }
    }

    .foot_inner {
        width: 950px;
        position: relative;
        z-index: 10;
        background-color: #f0f5fa;

        @media all and ($sm) {
            -webkit-justify-content: center;
            justify-content: center;
            width: 100%;
            background-color: #fff;
        }
    }

    .left {
        padding: 50px 40px 50px 0;
        background-color: #fff;

        @media all and ($sm) {
            padding: 40px 15px 30px;
        }
    }

    .right {
        padding: 50px 0 50px 50px;

        @media all and ($sm) {
            display: none;
        }

        >div {
            padding-right: 35px;
        }

        a {
            color: $c_navy;
            background: url(../images/arrow.svg) no-repeat center left;
            background-size: 8px;
            text-decoration: none;
            padding-left: 14px;
            font-size: 15px;
            font-weight: bold;
            white-space: nowrap;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        .ttl {
            color: $c_navy;
            font-size: 15px;
            white-space: nowrap;
        }

        .has_sub {
            .ttl {
                font-weight: bold;
            }

            a {
                color: #000;
                font-weight: normal;
            }
        }
    }
}

.btn__pagetop {
    position: absolute;
    top: -25px;
    right: -25px;

    @media all and ($sm) {
        top: -20px;
        right: 15px;
    }

    svg {
        fill: $c_navy;

        @media all and ($sm) {
            width: 16px;
        }
    }

    a {
        background-color: rgba($color: #c6d9ec, $alpha: .85);
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        padding-top: 14px;

        @media all and ($sm) {
            width: 40px;
            height: 40px;
            padding-top: 9px;
        }
    }
}

.copyright {
    text-align: center;
    background-color: #2e3167;
    color: #fff;
    margin: 0;
    padding: 12px 0;
    position: relative;
    z-index: 10;

    @media all and ($sm) {
        font-size: 14px;
    }
}