@charset "UTF-8";
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

a:focus {
  outline: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit],
select {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(14, 24, 14);
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 200%;
  margin: 0;
  font-feature-settings: "palt";
}

a {
  color: rgb(14, 24, 14);
  text-decoration: underline;
  transition: all 0.2s;
}
a:hover {
  text-decoration: none;
  color: rgb(14, 24, 14);
  opacity: 0.7;
}

.t_right {
  text-align: right !important;
}

.t_center {
  text-align: center !important;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}

.box-fadein img,
.box-fadein iframe {
  opacity: 0;
  -webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;
}
.box-fadein img.lazyloaded,
.box-fadein iframe.lazyloaded {
  opacity: 1;
}

@media all and (max-width: 599px) {
  .view_pc {
    display: none;
  }
}

.view_sp {
  display: none;
}
@media all and (max-width: 599px) {
  .view_sp {
    display: block;
  }
}

.c_navy {
  color: #2e3192;
}

#loading {
  width: 100vw;
  height: 100vh;
  transition: all 0.5s;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-justify-content: center;
  justify-content: center;
}
#loading.loaded {
  opacity: 0;
  visibility: hidden;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  50% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px -10px 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px -10px 0 0 #2e3192;
  }
  100% {
    box-shadow: 9984px 0 0 0 #2e3192, 9999px 0 0 0 #2e3192, 10014px 0 0 0 #2e3192;
  }
}
.inview .op1,
.inview .op2,
.inview .op3,
.inview .op4,
.inview .op5,
.inview .op6,
.inview .op7,
.inview .op8,
.inview .op9 {
  opacity: 0;
}
.inview.on .op1 {
  animation: my_opacity 0.8s ease-in-out 0.8s forwards;
}
.inview.on .op2 {
  animation: my_opacity 0.8s ease-in-out 1.2s forwards;
}
.inview.on .op3 {
  animation: my_opacity 0.8s ease-in-out 1.6s forwards;
}
.inview.on .op4 {
  animation: my_opacity 0.8s ease-in-out 2s forwards;
}
.inview.on .op5 {
  animation: my_opacity 0.8s ease-in-out 2.4s forwards;
}
.inview.on .op6 {
  animation: my_opacity 0.8s ease-in-out 2.8s forwards;
}
.inview.on .op7 {
  animation: my_opacity 0.8s ease-in-out 3.2s forwards;
}
.inview.on .op8 {
  animation: my_opacity 0.8s ease-in-out 3.6s forwards;
}
.inview.on .op9 {
  animation: my_opacity 0.8s ease-in-out 4s forwards;
}

@keyframes my_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ============================================
  header
============================================ */
.header {
  background-color: #fff;
}
@media all and (max-width: 599px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 100;
  }
  .header .logo {
    width: 170px;
    padding-left: 15px;
  }
  .header .logo a {
    display: block;
  }
}
.header .container {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 10px 0;
}
@media all and (max-width: 599px) {
  .header .container {
    padding: 0;
  }
}
.header .main-nav {
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
}
@media all and (max-width: 599px) {
  .header .main-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 70px 15px 0;
    z-index: 10;
    display: none;
  }
}
.header .main-nav .main-nav__list {
  padding: 0;
  -webkit-justify-content: center;
  justify-content: center;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list {
    padding-bottom: 10px;
  }
}
.header .main-nav .main-nav__list li {
  list-style-type: none;
  margin: 0 40px;
  font-size: 15px;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li {
    line-height: 100%;
    margin: 0 0 25px;
    font-size: 15px;
    width: 100%;
  }
}
.header .main-nav .main-nav__list li.menu-item-has-children {
  position: relative;
}
.header .main-nav .main-nav__list li.menu-item-has-children > a {
  border: none;
  font-weight: bold;
  background: none;
}
.header .main-nav .main-nav__list li.menu-item-has-children:hover .sub-menu {
  display: block;
  position: absolute;
  left: 0;
  z-index: 10;
  width: 180px;
  border-top: solid 1px #fff;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li.menu-item-has-children:hover .sub-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: static;
    width: 100%;
    border: none;
  }
}
.header .main-nav .main-nav__list li a {
  color: #fff;
  text-decoration: none;
  padding: 20px 0;
  display: block;
}
.header .main-nav .main-nav__list li a:hover {
  cursor: pointer;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li a {
    border-bottom: solid 1px #fff;
    display: block;
    padding: 0 0 10px;
    background: url(../images/arrow_nav.svg) no-repeat right top 3px;
    background-size: 5px;
  }
}
.header .main-nav .main-nav__list li .sub-menu {
  display: none;
  margin: 0;
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  padding: 0;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu {
    padding: 8px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background: none;
  }
}
.header .main-nav .main-nav__list li .sub-menu li {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
  border-bottom: solid 1px #fff;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu li {
    width: calc(50% - 6px);
    margin: 0 0 15px;
  }
}
.header .main-nav .main-nav__list li .sub-menu li a {
  display: block;
  padding: 20px;
}
@media all and (max-width: 599px) {
  .header .main-nav .main-nav__list li .sub-menu li a {
    padding: 0 0 8px;
  }
}
.header .main-nav .btn_contact {
  padding: 0;
  margin-bottom: 6px;
}
.header .main-nav .btn_contact a {
  width: 100%;
}
.header .main-nav .btn_contact svg {
  margin-right: 5px;
  width: 23px;
}
.header .main-nav .btn_contact span {
  display: block;
  font-weight: bold;
}
.header .main-nav .btns {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
.header .main-nav .btns .btn {
  width: calc(50% - 3px);
}
.header .main-nav .btns .btn:last-child {
  margin: 0;
}
.header .main-nav .btns .btn a {
  -webkit-justify-content: center;
  justify-content: center;
  font: 700 20px/120% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  border: solid 1px #fff;
  height: 60px;
  text-decoration: none;
  color: #fff;
  width: 100%;
}
.header .main-nav .btns .btn a svg {
  margin-right: 5px;
}
.header .main-nav .btns .btn.tel a {
  line-height: 100%;
  padding-top: 2px;
  background-color: #171a70;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header .main-nav .btns .btn.tel a > span {
  -webkit-justify-content: center;
  justify-content: center;
}
.header .main-nav .btns .btn.tel svg {
  width: 10px;
  height: 25px;
  margin: -2px 4px 0 0;
  fill: #fff;
}
.header .main-nav .btns .btn.tel .number {
  font: 500 18px/100% roboto, sans-serif;
  display: block;
  color: #fff;
}
.header .main-nav .btns .btn.tel .time {
  display: block;
  text-align: center;
  font-size: 13px;
  height: 20px;
}
.header .main-nav .btns .btn.line a {
  background-color: #171a70;
  font-size: 15px;
}
.header .main-nav .btns .btn.line svg {
  width: 30px;
}
.header .main-nav .btns .btn.line .cls-1 {
  fill: #00b900;
}
.header .main-nav .btns .btn.line .cls-2 {
  fill: #fff;
}
.header .tel {
  text-align: right;
  margin-right: 20px;
}
@media all and (max-width: 599px) {
  .header .tel {
    margin: 0;
  }
}
.header .tel .time {
  font-size: 12px;
}
@media all and (max-width: 599px) {
  .header .tel .time {
    display: none;
  }
}
.header .tel a {
  text-decoration: none;
}
@media all and (max-width: 599px) {
  .header .tel a {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 80px;
    text-align: center;
    background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
    background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
    background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
    background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  }
}
.header .tel .number {
  font: 500 26px/100% roboto, sans-serif;
  color: #2e3192;
}
@media all and (max-width: 599px) {
  .header .tel .number {
    display: none;
  }
}
.header .tel svg {
  fill: #2e3192;
  width: 12px;
  height: 20px;
  margin-right: 6px;
}
@media all and (max-width: 599px) {
  .header .tel svg {
    width: 21px;
    height: 32px;
    fill: #fff;
    margin: 0;
  }
}
@media all and (max-width: 599px) {
  .header .btn_contact {
    padding-right: 60px;
  }
}
.header .btn_contact a {
  color: #fff;
  text-decoration: none;
  padding: 20px;
  background: -webkit-gradient(linear, 45deg, from(#ff581b), to(rgba(255, 172, 51, 0.8)));
  background: -webkit-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
  background: -moz-linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
  background: linear-gradient(45deg, #ff581b, rgba(255, 172, 51, 0.8));
}
@media all and (max-width: 599px) {
  .header .btn_contact a {
    padding: 0;
    width: 60px;
    height: 60px;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.header .btn_contact span {
  font-weight: bold;
}
@media all and (max-width: 599px) {
  .header .btn_contact span {
    display: none;
  }
}
.header .btn_contact svg {
  width: 21px;
  margin-right: 10px;
}
@media all and (max-width: 599px) {
  .header .btn_contact svg {
    width: 30px;
    height: 23px;
    margin: 0;
  }
}
.header .btn_contact .cls-1 {
  fill: #fff;
}

#menuButton {
  display: none;
  width: 42px;
  height: 42px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 11;
}
@media all and (max-width: 599px) {
  #menuButton {
    display: block;
  }
}
#menuButton span {
  display: block;
  background: #2e3192;
  width: 24px;
  height: 2px;
  position: absolute;
  left: 9px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
#menuButton span:first-child {
  top: 12px;
}
#menuButton span:nth-child(2) {
  margin-top: -1px;
  top: 50%;
}
#menuButton span:last-child {
  bottom: 12px;
}
#menuButton.active span {
  background-color: #fff;
}
#menuButton.active span:first-child {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}
#menuButton.active span:nth-child(2) {
  opacity: 0;
}
#menuButton.active span:last-child {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

.breadcrumb {
  text-align: right;
  padding: 10px 0;
}
.breadcrumb .breadcrumb__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.breadcrumb .breadcrumb__list li:after {
  content: ">";
  margin: 0 10px 0 13px;
}
.breadcrumb .breadcrumb__list li:last-child:after {
  content: "";
  margin: 0;
}

@media all and (max-width: 599px) {
  .main {
    padding-top: 60px;
  }
}

/* ============================================
  area_contact
============================================ */
.area_contact {
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
}
.area_contact .container {
  width: 950px;
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  padding: 56px 0 50px;
  position: relative;
}
.area_contact .container:before, .area_contact .container:after {
  content: "";
  position: absolute;
  display: block;
}
.area_contact .container:before {
  width: 70px;
  height: 70px;
  top: -14px;
  left: -24px;
  background-color: rgba(174, 201, 228, 0.7);
}
@media all and (max-width: 599px) {
  .area_contact .container:before {
    width: 50px;
    height: 50px;
    left: 10px;
  }
}
.area_contact .container:after {
  width: 35px;
  height: 35px;
  top: 31px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
@media all and (max-width: 599px) {
  .area_contact .container:after {
    width: 25px;
    height: 25px;
    top: 20px;
    left: 43px;
  }
}
.area_contact h2 {
  text-align: center;
  font: 700 34px/130% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  margin-bottom: 30px;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.area_contact p {
  text-align: center;
  font: 400 18px/180% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  margin-bottom: 30px;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact p {
    font-size: 15px;
  }
}
.area_contact .btns {
  -webkit-justify-content: center;
  justify-content: center;
  width: 830px;
  margin: 0 auto;
}
@media all and (max-width: 599px) {
  .area_contact .btns {
    width: 100%;
    padding: 0 15px;
  }
}
.area_contact .btn {
  width: calc(33.3333333333% - 7px);
  margin-right: 10px;
}
.area_contact .btn:last-child {
  margin: 0;
}
.area_contact .btn a {
  -webkit-justify-content: center;
  justify-content: center;
  font: 700 20px/120% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  border: solid 1px #fff;
  height: 100px;
  text-decoration: none;
  color: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn a {
    height: 70px;
  }
}
.area_contact .btn a svg {
  margin-right: 15px;
}
@media all and (max-width: 599px) {
  .area_contact .btn.mail {
    width: 100%;
    margin: 0 0 6px;
  }
}
.area_contact .btn.mail a {
  background: -webkit-gradient(linear, left top, right top, from(#ff581b), to(rgba(255, 172, 51, 0.8)));
  background: -webkit-linear-gradient(left, #ff581b, rgba(255, 172, 51, 0.8));
  background: -moz-linear-gradient(left, #ff581b, rgba(255, 172, 51, 0.8));
  background: linear-gradient(to right, #ff581b, rgba(255, 172, 51, 0.8));
}
@media all and (max-width: 599px) {
  .area_contact .btn.mail a {
    border: none;
  }
  .area_contact .btn.mail a br {
    display: none;
  }
  .area_contact .btn.mail a svg {
    width: 23px;
    margin-right: 8px;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.mail a {
    font-size: 18px;
  }
}
.area_contact .btn.mail .cls-1 {
  fill: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel {
    width: calc(50% - 3px);
    margin-right: 6px;
  }
}
.area_contact .btn.tel a {
  line-height: 100%;
  padding-top: 2px;
  background-color: #171a70;
}
.area_contact .btn.tel a > span {
  -webkit-justify-content: center;
  justify-content: center;
}
.area_contact .btn.tel svg {
  width: 16px;
  height: 25px;
  margin: 2px 5px 0 0;
  fill: #fff;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel svg {
    width: 10px;
    margin: -2px 4px 0 0;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel svg {
    width: 9px;
    height: 18px;
  }
}
.area_contact .btn.tel .number {
  font: 500 30px/100% roboto, sans-serif;
  margin-bottom: 6px;
}
@media all and (max-width: 599px) {
  .area_contact .btn.tel .number {
    font-size: 18px;
    margin: 0;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel .number {
    font-size: 15px;
  }
}
.area_contact .btn.tel .time {
  display: block;
  text-align: center;
  font-size: 13px;
  height: 20px;
}
@media all and (max-width: 320px) {
  .area_contact .btn.tel .time {
    font-size: 11px;
  }
}
@media all and (max-width: 599px) {
  .area_contact .btn.line {
    width: calc(50% - 3px);
  }
}
.area_contact .btn.line a {
  background-color: #171a70;
}
@media all and (max-width: 599px) {
  .area_contact .btn.line a {
    font-size: 15px;
  }
  .area_contact .btn.line a svg {
    width: 30px;
  }
}
@media all and (max-width: 320px) {
  .area_contact .btn.line a {
    font-size: 13px;
  }
  .area_contact .btn.line a svg {
    margin-right: 8px;
  }
}
.area_contact .btn.line .cls-1 {
  fill: #00b900;
}
.area_contact .btn.line .cls-2 {
  fill: #fff;
}

/* ============================================
  footer
============================================ */
.footer {
  position: relative;
  background-color: #fff;
}
.footer:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  background-color: #f0f5fa;
  width: 50%;
  top: 0;
  left: 50%;
  z-index: 5;
}
@media all and (max-width: 599px) {
  .footer:after {
    display: none;
  }
}
.footer .foot_logo {
  margin-bottom: 26px;
}
.footer address {
  font-style: normal;
  background: url(../images/i_company.svg) no-repeat left top 7px;
  background-size: 14px;
  padding-left: 20px;
}
.footer .tel {
  background: url(../images/i_tel.svg) no-repeat left top 7px;
  background-size: 10px;
  padding-left: 20px;
}
.footer .tel a {
  text-decoration: none;
}
.footer .foot_inner {
  width: 950px;
  position: relative;
  z-index: 10;
  background-color: #f0f5fa;
}
@media all and (max-width: 599px) {
  .footer .foot_inner {
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
  }
}
.footer .left {
  padding: 50px 40px 50px 0;
  background-color: #fff;
}
@media all and (max-width: 599px) {
  .footer .left {
    padding: 40px 15px 30px;
  }
}
.footer .right {
  padding: 50px 0 50px 50px;
}
@media all and (max-width: 599px) {
  .footer .right {
    display: none;
  }
}
.footer .right > div {
  padding-right: 35px;
}
.footer .right a {
  color: #2e3192;
  background: url(../images/arrow.svg) no-repeat center left;
  background-size: 8px;
  text-decoration: none;
  padding-left: 14px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}
.footer .right ul {
  list-style-type: none;
  padding: 0;
}
.footer .right .ttl {
  color: #2e3192;
  font-size: 15px;
  white-space: nowrap;
}
.footer .right .has_sub .ttl {
  font-weight: bold;
}
.footer .right .has_sub a {
  color: #000;
  font-weight: normal;
}

.btn__pagetop {
  position: absolute;
  top: -25px;
  right: -25px;
}
@media all and (max-width: 599px) {
  .btn__pagetop {
    top: -20px;
    right: 15px;
  }
}
.btn__pagetop svg {
  fill: #2e3192;
}
@media all and (max-width: 599px) {
  .btn__pagetop svg {
    width: 16px;
  }
}
.btn__pagetop a {
  background-color: rgba(198, 217, 236, 0.85);
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 14px;
}
@media all and (max-width: 599px) {
  .btn__pagetop a {
    width: 40px;
    height: 40px;
    padding-top: 9px;
  }
}

.copyright {
  text-align: center;
  background-color: #2e3167;
  color: #fff;
  margin: 0;
  padding: 12px 0;
  position: relative;
  z-index: 10;
}
@media all and (max-width: 599px) {
  .copyright {
    font-size: 14px;
  }
}

.article > header {
  margin-bottom: 60px;
}
.article .mv {
  padding: 110px 0;
  background-size: cover;
}
@media all and (max-width: 599px) {
  .article .mv {
    background: url(../images/blog/mv@sp.jpg) no-repeat center center;
    background-size: cover;
    padding: 55px 0;
  }
}
.article .mv .ttl_page {
  text-align: center;
  color: #2e3192;
  font: bold 40px/100% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  letter-spacing: 3px;
}
@media all and (max-width: 599px) {
  .article .mv .ttl_page {
    font-size: 25px;
  }
}
@media all and (max-width: 599px) {
  .article .breadcrumb {
    display: none;
  }
}
.article .breadcrumb__item {
  font-size: 12px;
}
.article .content {
  margin-bottom: 150px;
}
@media all and (max-width: 599px) {
  .article .content {
    margin-bottom: 100px;
  }
}
.article .content h2 {
  text-align: center;
  color: #2e3192;
  font: bold 40px/140% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  letter-spacing: 3px;
  margin-bottom: 2rem;
}
@media all and (max-width: 599px) {
  .article .content h2 {
    font-size: 27px;
  }
}
.article .content p {
  margin-bottom: 1rem;
}
.article .content p.desc {
  text-align: center;
  margin-bottom: 40px;
}
@media all and (max-width: 599px) {
  .article .content p.desc {
    text-align: left;
  }
}
.article .content .has-text-align-center {
  text-align: center;
}
.article.post .container {
  width: 800px;
}
@media all and (max-width: 599px) {
  .article.post .container {
    padding: 0 15px;
  }
}
.article.post .post_title {
  font-size: 30px;
  line-height: 150%;
  color: #2e3192;
  margin-bottom: 30px;
}
@media all and (max-width: 599px) {
  .article.post .post_title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.article.post .date {
  color: #666;
}
.article.post .post-categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
}
.article.post .post-categories li {
  margin-right: 10px;
}
.article.post .post-categories li:last-child {
  margin: 0;
}
.article.post .post-categories a {
  display: block;
  background-color: #cedfef;
  text-decoration: none;
  line-height: 100%;
  color: #2e3192;
  padding: 10px 10px;
}
.article.post .thumbnail {
  margin-bottom: 40px;
}
.article.post .area_text p {
  margin-bottom: 1.5rem;
}
.article.post .nav-below {
  width: 800px;
  margin: 50px auto;
}
@media all and (max-width: 599px) {
  .article.post .nav-below {
    width: 100%;
    padding: 0 15px;
  }
}
.article.post .nav-below .nav-previous a,
.article.post .nav-below .nav-next a,
.article.post .nav-below .nav-list a {
  color: #2e3192;
  font-size: 18px;
  text-decoration: none;
  position: relative;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous a,
  .article.post .nav-below .nav-next a,
  .article.post .nav-below .nav-list a {
    font-size: 13px;
    line-height: 150%;
  }
}
.article.post .nav-below .nav-previous,
.article.post .nav-below .nav-next {
  width: calc(50% - 70px);
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous,
  .article.post .nav-below .nav-next {
    width: calc(50% - 32px);
  }
}
.article.post .nav-below .nav-previous a,
.article.post .nav-below .nav-next a {
  background-color: #f0f5fa;
  display: block;
}
.article.post .nav-below .nav-previous a:before,
.article.post .nav-below .nav-next a:before {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, 0.8));
  top: 0;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous a:before,
  .article.post .nav-below .nav-next a:before {
    width: 25px;
  }
}
.article.post .nav-below .nav-previous a:after,
.article.post .nav-below .nav-next a:after {
  content: "";
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  top: calc(50% - 11px);
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous a:after,
  .article.post .nav-below .nav-next a:after {
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
  }
}
.article.post .nav-below .nav-previous a {
  padding: 20px 20px 20px 70px;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous a {
    padding: 10px 10px 10px 35px;
  }
}
.article.post .nav-below .nav-previous a:before {
  left: 0;
}
.article.post .nav-below .nav-previous a:after {
  background: url(../images/blog/arrow_l.svg) no-repeat center center;
  background-size: 20px;
  left: 14px;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-previous a:after {
    background-size: 15px;
    left: 4px;
  }
}
.article.post .nav-below .nav-next a {
  padding: 20px 70px 20px 20px;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-next a {
    padding: 10px 35px 10px 10px;
  }
}
.article.post .nav-below .nav-next a:before {
  right: 0;
}
.article.post .nav-below .nav-next a:after {
  background: url(../images/blog/arrow_r.svg) no-repeat center center;
  background-size: 20px;
  right: 14px;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-next a:after {
    background-size: 15px;
    right: 4px;
  }
}
.article.post .nav-below .nav-list {
  width: 140px;
  text-align: center;
}
@media all and (max-width: 599px) {
  .article.post .nav-below .nav-list {
    width: 64px;
  }
}
.article.a404 .mv {
  padding: 110px 0;
  background: url(../images/contact/mv@sp.jpg) no-repeat center center;
  background-size: cover;
}
@media all and (max-width: 599px) {
  .article.a404 .mv {
    padding: 55px 0;
  }
}
.article.a404 .mv h1 {
  text-align: center;
  color: #2e3192;
  font: bold 40px/100% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  letter-spacing: 3px;
}
@media all and (max-width: 599px) {
  .article.a404 .mv h1 {
    font-size: 25px;
  }
}

.archive_header {
  margin-bottom: 60px;
}
.archive_header .mv {
  padding: 110px 0;
  background-size: cover;
}
@media all and (max-width: 599px) {
  .archive_header .mv {
    background: url(../images/blog/mv@sp.jpg) no-repeat center center;
    background-size: cover;
    padding: 55px 0;
  }
}
.archive_header .mv .ttl_page {
  text-align: center;
  color: #2e3192;
  font: bold 40px/100% "Noto Sans JP", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "Avenir Next", Verdana, sans-serif;
  letter-spacing: 3px;
}
@media all and (max-width: 599px) {
  .archive_header .mv .ttl_page {
    font-size: 25px;
  }
}
@media all and (max-width: 599px) {
  .archive_header .breadcrumb {
    display: none;
  }
}
.archive_header .breadcrumb__item {
  font-size: 12px;
}

@media all and (max-width: 599px) {
  .posts_archive {
    padding: 0 15px;
  }
}
.posts_archive .list_category {
  -webkit-justify-content: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-bottom: 50px;
}
@media all and (max-width: 599px) {
  .posts_archive .list_category {
    margin-bottom: 30px;
  }
}
.posts_archive .list_category li {
  font-size: 18px;
  margin-right: 35px;
}
.posts_archive .list_category li:last-child {
  margin: 0;
}
@media all and (max-width: 599px) {
  .posts_archive .list_category li {
    font-size: 14px;
    margin: 0 25px 20px 0;
  }
}
.posts_archive .list_category li a {
  text-decoration: none;
  color: #c6c6c6;
}
.posts_archive .list_category li a:hover {
  color: #2e3192;
  border-bottom: solid 3px #2e3192;
  padding-bottom: 4px;
}
.posts_archive .list_category li.current-cat a {
  color: #2e3192;
  border-bottom: solid 3px #2e3192;
  padding-bottom: 4px;
}
.posts_archive .block {
  width: calc(33.3333333333% - 27px);
  margin: 0 40px 70px 0;
  position: relative;
  z-index: 3;
  border: solid 1px #ececec;
}
.posts_archive .block:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 599px) {
  .posts_archive .block {
    width: 100%;
    margin: 0 0 40px;
  }
}
.posts_archive .date {
  padding: 30px 30px 8px;
  font-size: 12px;
  color: #666;
}
.posts_archive h3 {
  padding: 0 30px 18px;
}
.posts_archive h3 a {
  text-decoration: none;
  color: #333;
}
.posts_archive .category {
  padding: 0 30px 30px;
  list-style-type: none;
}
.posts_archive .category li {
  font-size: 14px;
  background-color: #cedfef;
  color: #2e3192;
  font-weight: bold;
  line-height: 100%;
  padding: 10px;
  margin-right: 5px;
}
.posts_archive .category li:last-child {
  margin: 0;
}
.posts_archive .btn_more {
  position: absolute;
  right: -18px;
  bottom: -18px;
}
@media all and (max-width: 599px) {
  .posts_archive .btn_more {
    right: 15px;
  }
}
.posts_archive .btn_more a {
  background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  display: block;
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
}
@media all and (max-width: 599px) {
  .posts_archive .btn_more a {
    width: 40px;
    height: 40px;
    line-height: 50px;
  }
}
.posts_archive .btn_more a svg {
  fill: #fff;
}
@media all and (max-width: 599px) {
  .posts_archive .btn_more a svg {
    width: 17px;
  }
}

/* ============================================
  Pagenation
============================================ */
.pager {
  text-align: center;
  margin: 0 0 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
}
.pager .current,
.pager a.page-numbers {
  background: #eee;
  border: 1px solid #ccc;
  padding: 13px 20px;
  margin: 10px 4px 0;
  text-decoration: none;
  display: block;
}
.pager .current {
  background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, 0.8)));
  background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, 0.8));
  color: #fff;
}
.pager a:link,
.pager a:visited,
.pager a:hover,
.pager a:active {
  color: #333;
}