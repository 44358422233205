@import "_common";

.article {
    >header {
        margin-bottom: 60px;
    }

    .mv {
        padding: 110px 0;
        background-size: cover;

        @media all and ($sm) {
            background: url(../images/blog/mv@sp.jpg) no-repeat center center;
            background-size: cover;
            padding: 55px 0;
        }

        .ttl_page {
            text-align: center;
            color: $c_navy;
            font: bold 40px/100% $font_g;
            letter-spacing: 3px;

            @media all and ($sm) {
                font-size: 25px;
            }
        }
    }

    .breadcrumb {
        @media all and ($sm) {
            display: none;
        }
    }

    .breadcrumb__item {
        font-size: 12px;
    }

    .content {
        margin-bottom: 150px;

        @media all and ($sm) {
            margin-bottom: 100px;
        }

        h2 {
            text-align: center;
            color: $c_navy;
            font: bold 40px/140% $font_g;
            letter-spacing: 3px;
            margin-bottom: 2rem;

            @media all and ($sm) {
                font-size: 27px;
            }
        }

        p {
            margin-bottom: 1rem;

            &.desc {
                text-align: center;
                margin-bottom: 40px;

                @media all and ($sm) {
                    text-align: left;
                }
            }
        }

        .has-text-align-center {
            text-align: center;
        }
    }

    &.post {
        .container {
            width: 800px;

            @media all and ($sm) {
                padding: 0 15px;
            }
        }

        .post_title {
            font-size: 30px;
            line-height: 150%;
            color: $c_navy;
            margin-bottom: 30px;

            @media all and ($sm) {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .date {
            color: #666;
        }

        .post-categories {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            list-style-type: none;
            padding: 0;
            margin: 20px 0 40px;

            li {
                margin-right: 10px;

                &:last-child {
                    margin: 0;
                }
            }

            a {
                display: block;
                background-color: #cedfef;
                text-decoration: none;
                line-height: 100%;
                color: $c_navy;
                padding: 10px 10px;
            }
        }

        .thumbnail {
            margin-bottom: 40px;
        }

        .area_text {
            p {
                margin-bottom: 1.5rem;
            }
        }

        .nav-below {
            width: 800px;
            margin: 50px auto;

            @media all and ($sm) {
                width: 100%;
                padding: 0 15px;
            }

            .nav-previous,
            .nav-next,
            .nav-list {
                a {
                    color: $c_navy;
                    font-size: 18px;
                    text-decoration: none;
                    position: relative;

                    @media all and ($sm) {
                        font-size: 13px;
                        line-height: 150%;
                    }
                }
            }

            .nav-previous,
            .nav-next {
                width: calc(50% - 70px);

                @media all and ($sm) {
                    width: calc(50% - 32px);
                }

                a {
                    background-color: #f0f5fa;
                    display: block;

                    &:before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 50px;
                        height: 100%;
                        background: -webkit-gradient(linear, left top, right top, from(#2e3167), to(rgba(46, 49, 146, .8)));
                        background: -webkit-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                        background: -moz-linear-gradient(left, #2e3167, rgba(46, 49, 146, .8));
                        background: linear-gradient(to right, #2e3167, rgba(46, 49, 146, .8));
                        top: 0;

                        @media all and ($sm) {
                            width: 25px;
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 22px;
                        height: 22px;
                        top: calc(50% - 11px);

                        @media all and ($sm) {
                            width: 16px;
                            height: 16px;
                            top: calc(50% - 8px);
                        }
                    }

                }
            }

            .nav-previous {
                a {
                    padding: 20px 20px 20px 70px;

                    @media all and ($sm) {
                        padding: 10px 10px 10px 35px;
                    }

                    &:before {
                        left: 0;
                    }

                    &:after {
                        background: url(../images/blog/arrow_l.svg) no-repeat center center;
                        background-size: 20px;
                        left: 14px;

                        @media all and ($sm) {
                            background-size: 15px;
                            left: 4px;
                        }
                    }
                }
            }

            .nav-next {
                a {
                    padding: 20px 70px 20px 20px;

                    @media all and ($sm) {
                        padding: 10px 35px 10px 10px;
                    }

                    &:before {
                        right: 0;
                    }

                    &:after {
                        background: url(../images/blog/arrow_r.svg) no-repeat center center;
                        background-size: 20px;
                        right: 14px;

                        @media all and ($sm) {
                            background-size: 15px;
                            right: 4px;
                        }
                    }
                }
            }

            .nav-list {
                width: 140px;
                text-align: center;

                @media all and ($sm) {
                    width: 64px;
                }
            }
        }
    }

    &.a404 {
        .mv {
            padding: 110px 0;
            background: url(../images/contact/mv@sp.jpg) no-repeat center center;
            background-size: cover;

            @media all and ($sm) {
                padding: 55px 0;
            }

            h1 {
                text-align: center;
                color: $c_navy;
                font: bold 40px/100% $font_g;
                letter-spacing: 3px;

                @media all and ($sm) {
                    font-size: 25px;
                }
            }
        }
    }
}

.archive_header {
    margin-bottom: 60px;

    .mv {
        padding: 110px 0;
        background-size: cover;

        @media all and ($sm) {
            background: url(../images/blog/mv@sp.jpg) no-repeat center center;
            background-size: cover;
            padding: 55px 0;
        }

        .ttl_page {
            text-align: center;
            color: $c_navy;
            font: bold 40px/100% $font_g;
            letter-spacing: 3px;

            @media all and ($sm) {
                font-size: 25px;
            }
        }
    }

    .breadcrumb {
        @media all and ($sm) {
            display: none;
        }
    }

    .breadcrumb__item {
        font-size: 12px;
    }
}

.posts_archive {
    @media all and ($sm) {
        padding: 0 15px;
    }

    .list_category {
        -webkit-justify-content: center;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        margin-bottom: 50px;

        @media all and ($sm) {
            margin-bottom: 30px;
        }

        li {
            font-size: 18px;
            margin-right: 35px;

            &:last-child {
                margin: 0;
            }

            @media all and ($sm) {
                font-size: 14px;
                margin: 0 25px 20px 0;
            }

            a {
                text-decoration: none;
                color: #c6c6c6;

                &:hover {
                    color: $c_navy;
                    border-bottom: solid 3px $c_navy;
                    padding-bottom: 4px;
                }
            }

            &.current-cat {
                a {
                    color: $c_navy;
                    border-bottom: solid 3px $c_navy;
                    padding-bottom: 4px;
                }
            }
        }
    }

    .block {
        width: calc(100% / 3 - 27px);
        margin: 0 40px 70px 0;
        position: relative;
        z-index: 3;
        border: solid 1px #ececec;

        &:nth-child(3n) {
            margin-right: 0;
        }

        @media all and ($sm) {
            width: 100%;
            margin: 0 0 40px;
        }
    }

    .date {
        padding: 30px 30px 8px;
        font-size: 12px;
        color: #666;
    }

    h3 {
        padding: 0 30px 18px;

        a {
            text-decoration: none;
            color: #333;
        }
    }

    .category {
        padding: 0 30px 30px;
        list-style-type: none;

        li {
            font-size: 14px;
            background-color: #cedfef;
            color: $c_navy;
            font-weight: bold;
            line-height: 100%;
            padding: 10px;
            margin-right: 5px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .btn_more {
        position: absolute;
        right: -18px;
        bottom: -18px;

        @media all and ($sm) {
            right: 15px;
        }

        a {
            background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, .8)));
            background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
            display: block;
            width: 50px;
            height: 50px;
            line-height: 60px;
            text-align: center;

            @media all and ($sm) {
                width: 40px;
                height: 40px;
                line-height: 50px;
            }

            svg {
                fill: #fff;

                @media all and ($sm) {
                    width: 17px;
                }
            }
        }
    }
}

/* ============================================
  Pagenation
============================================ */

.pager {
    text-align: center;
    margin: 0 0 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;

    .current,
    a.page-numbers {
        background: #eee;
        border: 1px solid #ccc;
        padding: 13px 20px;
        margin: 10px 4px 0;
        text-decoration: none;
        display: block;
    }

    .current {
        background: -webkit-gradient(linear, 45deg, from(#2e3167), to(rgba(46, 49, 146, .8)));
        background: -webkit-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        background: -moz-linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        background: linear-gradient(45deg, #2e3167, rgba(46, 49, 146, .8));
        color: #fff;
    }

    a:link,
    a:visited,
    a:hover,
    a:active {
        color: #333;
    }
}